














































































import Vue from 'vue';

import RCP from '@/api/RCP.ts';

import StatisticsTable from '@/components/statistics/Table.vue';
import DatePicker from '@/components/DatePicker.vue';
import SelectField from '@/components/SelectField.vue';

import { ErrorResponse } from '@/types/Errors';

export default Vue.extend({
    name: 'UTMCampaign',
    data() {
      const today = new Date();
      let year = today.getFullYear();
      const monthCalc = today.getMonth() + 1;
      let month = ('0' + (monthCalc)).slice(-2);
      const day = '01';
      let secondYear = year;
      let secondMonth = ('0' + (monthCalc)).slice(-2);
      const secondDay = new Date(year, monthCalc, 0).getDate()

        if (month === '12') {
            secondYear += 1;
            secondMonth = '01';
        }

        let first = '';
        let second = '';

        first += `${year}-${month}-${day}`;
        second += `${secondYear}-${secondMonth}-${secondDay}`;

        return {
            table: [],
            projectsMap: new Map(),
            projects: [],
            partners: [],
            options: {
                status: {
                    new: 'Первичники',
                    old: 'Повторники'
                },
                source: {
                    all: 'Все',
                    new_profile: 'Новые',
                    old_profile: 'Старые'
                }
            },
            params: {
                from: first,
                to: second,
                status: 'new',
                source: 'all',
                project: 0,
                partner_id: 'all'
            },
            error: '',
            request: false,
            datesError: false,
            datesWatcher: () => { return; }
        };
    },
    created() {
        this.getFilters().then(this.getResults);
    },
    watch: {
        'projects'(val: any) {
            if (val.length === 0) {
                return;
            }

            this.params.project = val[0].id;

            this.projectsMap = new Map(val.map((element: any) => {
                return [element.id, element.partners];
            }));
        },
        'params.project'(val: any) {
            this.params.partner_id = 'all';
            this.partners = this.projectsMap.get(val);
        }
    },
    methods: {
        validate() {
            const fromArr = this.params.from.split('-');
            const toArr = this.params.to.split('-');

            let valid = true;

            if ((fromArr[0] > toArr[0]) ||
                (fromArr[0] === toArr[0] && fromArr[1] > toArr[1]) ||
                (fromArr[0] === toArr[0] && fromArr[1] === toArr[1] && fromArr[2] > toArr[2])) {
                valid = false;
            }

            if (valid) {
                this.getResults();
            } else {
                this.datesErrorActivate();
            }
        },
        datesErrorActivate() {
            this.datesError = true;
            this.datesWatcher = this.$watch('params', () => {
                this.datesError = false;
                this.datesWatcher();
            }, {
                deep: true
            });
        },
        getFilters() {
            return new Promise((resolve) => {
                this.projects = this.$store.state.filters.projects;
                resolve();
            });
        },
        getResults() {
            if (!this.request) {
                this.request = true;
                this.error = '';

                this.table = [];

                const params = {
                    from: this.params.from,
                    to: this.params.to,
                    status: this.params.status,
                    projects: [ this.params.project ]
                } as any;

                if (this.params.partner_id !== 'all') {
                    params.partner_id = +this.params.partner_id;
                }

                if (this.params.source !== 'all') {
                    params.source = this.params.source;
                }

                RCP({
                    method: 'Data.campaign',
                    params,
                    id: 'getResults'
                }).then((result: any) => {
                    this.table = result;
                    this.request = false;
                }).catch((error: ErrorResponse) => {
                    this.error = error.message;
                    this.request = false;
                });
            }
        }
    },
    components: {
        StatisticsTable,
        DatePicker,
        SelectField
    }
});
